import React from 'react';

import { PodcastsMainComponent } from '@pages-impl/podcasts/podcasts-main-component/PodcastsMainComponent';
import featuredImage from '@images/header-images/featured_image_PP_Network.jpg';

export default function Podcasts(props) {
  const SEO_title = 'Network and service monitoring in heterogeneous environments';
  const SEO_description =
    'We present a podcast where we talk about network and service monitoring. How does it work in heterogeneous environments? Listen to the podcast.';

  return (
    <PodcastsMainComponent
      {...props}
      SEO_description={SEO_description}
      SEO_title={SEO_title}
      featuredImage={featuredImage}
    />
  );
}
